export default class Loading
{
    mostrar?:boolean =false;
    mensaje?:string = "";

    constructor(_mostrar?:boolean,_mensaje?:string)
    {
        this.mostrar = _mostrar;
        this.mensaje = _mensaje;
    }
}