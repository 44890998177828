




























































































import {Vue, Component, Watch} from 'vue-property-decorator'
import {State, Action, Mutation} from 'vuex-class'
import { IParteCabeceraAprobacion } from '@/store/Parte/types';
import {ParterMayordomoRequest,ObtenerParteMayordomo,ParteModelConsulta,ParteDetalleModel,ObtenerParteDetalleImagen,ObtenerParteDetalle,DescargarPdf} from '@/entidades/Plantacion/Partes/Consulta/ParteModelConsulta';
import Loading from '@/entidades/Sistema/Loading';
import Alerta from '@/entidades/Sistema/Alerta';
import ResponseGenerico from '@/entidades/ResponseGenerico';
import moment from 'moment';

@Component({
    components:{
        'Confirmacion' :() => import('@/components/Confirmacion.vue'),
        'tabla' : () => import('@/components/General/TablaGeneralFinal.vue'),
        'cardImagen' : () =>import('@/components/General/VisualizarImagen.vue'),
        'flujoAprobacion' : () =>import('@/components/Forestal/Parte/FlujoAprobacion.vue')
    }
})
export default class extends Vue
{   
    @State('usuarioId',{namespace:'authModule'}) usuarioId!:string;
    @Action('cargarPartesPendientes', {namespace:'parteModule'}) cargarPartesPendientes!:Function;
    @Action('enviarAprobacion', {namespace:'parteModule'}) enviarAprobacion!:Function;
    @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function;
    @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!:Function;
    dialogAprobar:boolean = false;
    dialogRechazar:boolean = false;
    tab:number = 0;
    tabs:Array<number> = [0,1];
    componenteParte:string = "";
    componentDialog="";
    motivoRechazo:string ="";
    headers:any[]= 
    [
        {
          text: 'Parte #',
          align: 'start',
          sortable: true,
          value: 'parteId'
        },
        {
          text: 'Fecha',
          align: 'start',
          sortable: true,
          value:'fecha'
        },
        {
          text: 'Hacienda',
          align: 'start',
          sortable: true,
          value: 'haciendaName'
        },
        {
          text: 'Orden interna',
          align: 'start',
          sortable: true,
          value:'ordenInterna'
        },
         {
          text: 'Estado',
          align: 'start',
          sortable: true,
          value:'estado'
        },
        { text: 'Acciones', align:'center', value: 'actions', sortable: false },       
    ];

      headers2:any[]= 
    [
        {
          text: 'Fecha',
          align: 'start',
          sortable: true,
          value:'fecha'
        },
        {
          text: '# Parte',
          align: 'start',
          sortable: false,
          value:'parteIdCustomer'
        },
        {
          text: 'Hacienda',
          align: 'start',
          sortable: false,
          value: 'haciendaName'
        },
        {
          text: 'Mayordomo',
          align: 'start',
          sortable: false,
          value:'mayordomo'
        },
         {
          text: 'Rechazo',
          align: 'start',
          sortable: false,
          value:'usuarioRechazo',
        },
         {
          text: 'Motivo',
          align: 'start',
          sortable: false,
          value:'motivo',
        },
        
        { text: 'Acciones', align:'center', value: 'acciones', sortable: false },       
    ];


    cabeceraDetalles:any[]= 
    [
        {
          text: 'Labor',
          align: 'start',
          sortable: false,
          value: 'laborName'
        },
        {
          text: 'Lote',
          align: 'start',
          sortable: false,
          value:'loteName'
        },
        {
          text: 'Cuadrilla',
          align: 'start',
          sortable: false,
          value:'cuadrillaName'
        },
        {
          text: 'Cantidad',
          align: 'end',
          sortable: false,
          value:'cantidad'
        },
        // {
        //   text: 'Plantulas',
        //   align: 'end',
        //   sortable: false,
        //   value:'cantidadPlantula'
        // },
        {
          text: 'Precio',
          align: 'end',
          sortable: false,
          value:'precio'
        },
        { text: 'Acciones', align:'center', value: 'actions', sortable: false },  
    ];
    
    fechaUnoOpen:boolean=false;
    fechaHastaOpen:boolean=false;
    fechaDesde:string=moment().startOf('month').format('YYYY-MM-DD');
    fechaHasta:string=moment().endOf('month').format('YYYY-MM-DD');
    listadoPartes:Array<ParteModelConsulta> =[];
    mensaje=''
    detalleParte=new Array<ParteDetalleModel>();
    parteId=0;
    mostrandoDetalles=false;
    decidiendo=false;
    aprobado=false;
    parteTmp?:IParteCabeceraAprobacion;
    Detallando=false;
    Motivo=''
    modalConfirmacion:boolean=false;
    messageConfirmacion:string="";
    propsEnviar:any={};
    imagenComponent:string="";
    imagenModal:boolean=false;
    imagenString:string="";
    itemTmp:ParteModelConsulta | undefined = undefined;
    requestMayordomo:ParterMayordomoRequest = { 
      desde:'',hasta :'',userId:''
    };
    async descargarPdf(parte:ParteModelConsulta)
    {
      try
      {
        this.changeLoading(new Loading(true,"Descargando pdf"));
        var response = await DescargarPdf(parte.parteId!);
        let blob = new Blob([response], {type: 'application/pdf'})
        var url = window.URL.createObjectURL(blob);
        window.open(url);
      }
      catch(error)
      {

      }
      finally
      {
        this.changeLoading(new Loading(false,"Descargando pdf"));
     }
    }
    async abrirVerDetalles(parte: ParteModelConsulta)
    {
      console.log(parte);
      try
      {
        this.changeLoading(new Loading(true,"Cargando informacion"));
        var response = await ObtenerParteDetalle(parte.parteId!);
        if(response.isSuccess)
        {
           
          this.detalleParte = response.isResult;
          this.mostrandoDetalles=true;
        }
        else
        {
             this.changeAlerta(new Alerta(response.isMessage,true,"danger",1000));
        }
       
      }
      catch(error)
      {

      }
      finally
      {
        this.changeLoading(new Loading(false,""));
      }
       
    }


    async ObtenerParte()
    {
        try
        {
            this.changeLoading(new Loading(true,"Cargando informacion"));
            this.requestMayordomo.userId = this.usuarioId;
            this.requestMayordomo.desde = this.fechaDesde;
            this.requestMayordomo.hasta = this.fechaHasta;
            let rsp = await ObtenerParteMayordomo(this.requestMayordomo);
            if(rsp.isSuccess == true)
            {
                this.listadoPartes = rsp.isResult;
            }
            else
            {
                 this.changeAlerta(new Alerta(rsp.isMessage,true,"danger",1000));
                 this.listadoPartes =[];
            }
        }
        catch(error)
        {

        }
        finally
        {
            this.changeLoading(new Loading(false,""));
        }
    }
    abrirFlujoAprobacion(item:ParteModelConsulta)
    {
      this.parteId = item.parteId!;
      this.imagenComponent = 'flujoAprobacion';
      this.imagenModal= true;
    }

    salir()
    {
      this.dialogAprobar = false;
      this.dialogRechazar = false;
      this.itemTmp = undefined;
    }

  async visualizarImagen(item:ParteDetalleModel)
    {
      try
      {
          this.changeLoading(new Loading(true,"Cargando informacion"));
          var response = await ObtenerParteDetalleImagen(item.parteDetalleId!);
          console.log(response);
          if(response.isSuccess)
          {
             this.imagenComponent = "cardImagen";
              this.imagenString = response.isMessage;
              this.imagenModal = true;
          }
          else
          {
            this.changeAlerta(new Alerta(response.isMessage,true,"danger",1000));
          }
      }
      catch(error)
      {
        //@ts-ignore
        this.changeAlerta(new Alerta(error,true,"danger",1000));
      }
      finally
      {
             this.changeLoading(new Loading(false,""));
      }
        if(item.imagen == "")
        {
          this.changeAlerta(new Alerta("No se puede visualizar imagen, no se encontro la imagen",true,"danger",3000));
          return;
        }
       


    }


    getFechaDesde(fecha:string)
    {
      this.fechaDesde = fecha;
    }
    getFechaHasta(fecha:string)
    {
      this.fechaHasta = fecha;
    }
    //WATCH
    // @Watch('tab')
    // filtroBusqueda(valueNew:number,valueOld:number)
    // {
    //     if(valueNew == 0)
    //     {
    //         this.parametroFiltro.usuarioId= this.usuarioId;
    //         this.parametroFiltro.tipo = false;
    //     }
    //     else if(valueNew == 1)
    //     {
    //         this.parametroFiltro.usuarioId= this.usuarioId;
    //         this.parametroFiltro.tipo = true;  
    //     }
    //     this.listadoPartes =[];
    //     this.ObtenerParte();
    // }

    @Watch('imagenModal')
    modelModalComponent(valueNew:boolean,valueOld:boolean)
    {
      if(valueNew == false)
      {
        this.imagenComponent = '';
      }
    }

    //computed
   
    mounted() {
      this.changeAlerta(new Alerta("",false,"danger",0));
      this.ObtenerParte();
    }
}
