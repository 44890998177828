import { ObtenerPost2,Editar, Save ,DescargarFilePdf,Obtener} from "@/Generico/Conexion";
import ResponseCustom from '@/entidades/ResponseCustom';
import { secondAxios } from "@/plugins/axios";
export class ParteModelConsulta
{
    parteId?: number;
    parteIdCustomer?: string;
    parteAprobacionId?: number;
    haciendaName?: string;
    mayordomo?: string;
    fecha?: string;
    motivo?: string;
    excede?: boolean;
    usuarioRechazo?: string;
    nivelActual!:number;
    nivelMaximo!:number;
    orderInterna!:string;
    estado!:string;
    aprobada!:boolean;
    rechazada!:boolean;
    haciendaId!:number;
    usuarioId!:string;
    tipoCuadrilla!:number
    items?:Array<ParteDetalleModel> =[];
}

export class ParteQueryParametro 
{
    usuarioId?: string;
    tipo?: boolean;
}

export class ParteDetalleModel
{
    parteDetalleId?: number;
    laborId?: number;
    loteId?: number;
    cuadrillaId?: number;
    laborName?: string;
    cuadrillaName?: string;
    loteName?: string;
    cantidad?: number;
    cantidadExcede?: number;
    motivo?: string;
    cantidadPlantula?: number;
    precio?: number;
    cicloId?: number;
    fecha?: string;
    imagen!:string;
    
}

export class AprobacionRechazarModal 
{
    parteId: number;
    usuarioId: string;
    parteAprobacionId: number;
    aprobado: boolean;
    motivo: string;
    nivelActual:number;
    nivelMaximo:number;
    constructor(_parteId:number,_usuarioId:string,_parteAprobacionId:number,
            _aprobado:boolean,_motivo:string,_nivelActual:number,_nivelMaximo:number)
            {
                this.parteId = _parteId;
                this.usuarioId = _usuarioId;
                this.parteAprobacionId = _parteAprobacionId;
                this.aprobado=_aprobado;
                this.motivo = _motivo;
                this.nivelActual = _nivelActual;
                this.nivelMaximo = _nivelMaximo;
            }
}

export interface ParterMayordomoRequest
{
    desde:string;
    hasta:string;
    userId:string;
}

export interface FlujoAprobacionModal {
    nivel: number;
    usuario: string;
    aprobado: boolean;
    rechazado: boolean;
    motivo: string;
    estado: string;
    fecha: string;
}


export async function ObtenerParteMayordomo(query:ParterMayordomoRequest):Promise<ResponseCustom<ParteModelConsulta>>
{

    return ObtenerPost2<ParteModelConsulta,ParterMayordomoRequest>('parte/obtenerpartemayordomo/',query,secondAxios);
}



export async function ObtenerPartePendiente(query:ParteQueryParametro):Promise<ResponseCustom<ParteModelConsulta>>
{
    return Obtener<ParteModelConsulta>(`parteaprobacion/ObtenerPartePendiente/${query.usuarioId}`,secondAxios);
}

export async function ObtenerParteDetalle(parteId:number):Promise<ResponseCustom<ParteDetalleModel>>
{

    return Obtener<ParteDetalleModel>(`parte/obtenerpartedetalle/${parteId}`,secondAxios);
}

export async function ObtenerParteDetalleImagen(detalleId:number):Promise<ResponseCustom<ParteDetalleModel>>
{

    return Obtener<ParteDetalleModel>(`parte/obtenerimagen/${detalleId}`,secondAxios);
}



export async function EditarParte(query:ParteModelConsulta)
{

    return Editar<ParteModelConsulta>('parte/EditarParte',query,secondAxios);
}

export async function AprobarParte(aprobacion:AprobacionRechazarModal[])
{
    return Save<AprobacionRechazarModal[]>('ParteAprobacion/AprobarParte',aprobacion,secondAxios);
}

export async function RechazarParte(aprobacion:AprobacionRechazarModal)
{
    return Save<AprobacionRechazarModal>('ParteAprobacion/RechazarParte',aprobacion,secondAxios);
}


export async function DescargarPdf(parteId:number)
{
    return DescargarFilePdf(`parte/generarpdf/${parteId}`,secondAxios);
}

export async function ObtenerFlujoAprobacion(parteId:number)
{
    return Obtener<FlujoAprobacionModal>(`parte/obtenerFlujoAprobacion/${parteId}`,secondAxios);
}